var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wechat-webview" }, [
    _c("div", { staticClass: "info-header" }, [_vm._v(_vm._s(_vm.info.title))]),
    _c("div", {
      staticClass: "info-content",
      domProps: { innerHTML: _vm._s(_vm.info.content) }
    }),
    _c("div", { staticClass: "info-footer" }, [
      _c("div", { staticClass: "logo" }, [_vm._v("鱼大大")]),
      _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.time))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
require("core-js/modules/es.array.concat");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/toast/style", "vant/es/toast"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/toast/style"), require("vant/es/toast"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.toast);
    global.wechatWebView = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _toast) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _toast = _interopRequireDefault(_toast);
  var _default = {
    name: "WechatWebView",
    data: function data() {
      return {
        info: {},
        time: ''
      };
    },
    created: function created() {
      var _this$$route$query = this.$route.query,
          token = _this$$route$query.token,
          id = _this$$route$query.id,
          type = _this$$route$query.type;
      !token ? this.$router.replace({
        path: "/404"
      }) : this.loadInfo(token, id, type);
    },
    methods: {
      loadInfo: function loadInfo(token, id, type) {
        var vm = this;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base[type == 1 ? 'MP_WECHAT_BASE_URL' : 'DEALER_WECHAT_BASE_URL'], "notice/").concat(id),
          headers: {
            'x-access-token': token
          },
          params: {
            probeId: vm.$route.query.uuid
          },
          except: true
        }).then(function (res) {
          if (res.code != 1) {
            (0, _toast.default)(res.message || "网络错误");
            return;
          }

          if (res.data) {
            var time = new Date(res.data.releaseAt * 1000);
            vm.time = "".concat(time.getMonth() + 1, "-").concat(time.getDate());
          }

          vm.info = res.data;
        });
      }
    }
  };
  _exports.default = _default;
});